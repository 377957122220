$header_background_color: #fff;
$text_color: #242727;
$alternate_row_background_color: #ddd;

.tbl-fixed-header {
  table-layout: fixed;
  background: $header_background_color;
  color: $text_color;
  border-collapse: inherit;

  th,
  td {
    padding: 5px;
    text-align: center;
    vertical-align: top;
    width: 100px;
  }

  td:nth-child(1) {
    text-align: left;
    width: 200px;
  }

  thead {
    background-color: $header_background_color;
    color: $text_color;
    tr {
      position: relative;
    }
  }

  tbody {
    overflow: auto;
    width: 100%;
    height: 100%;
    tr:nth-child(odd) {
      background-color: $alternate_row_background_color;
    }
  }
}

.search-field {
  vertical-align: super;
  display: inline-block;
  padding: 0px 5px 5px 5px;
}

.quantity {
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

label {
  text-align: left;
  display: block;
}

.react-datepicker-popper {
  z-index: 14 !important;
}

.react-datepicker {
  z-index: 15 !important;
}

/******** Autocomplete ********/
.suggestions {
  border: 1px solid #999;
  background: #ddd;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
  z-index: 15;
  position: absolute;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  border-left: 2px solid #fe5029;
  background-color: #ccc;
  color: #000000;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
