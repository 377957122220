.input {
  align-items: center;
  box-sizing: border-box;
  color: rgb(33, 37, 41);
  cursor: default;
  display: flex;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 400;
  height: 32px;
  line-height: 20.8px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  position: relative;
  text-align: left;
  width: 166.5px;
  -webkit-box-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  padding: 2px 8px;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  -ms-flex-align: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  -webkit-transition: all 100ms;
  transition: all 100ms;
}

.inputmedium {
  width: 195px;
}
