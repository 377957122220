.container {
  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.wrapper {
  @include themify($themes) {
    background: themed("colorBackgroundBody");
  }

  &.wrapper--full-width {
    .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.top-navigation {
    .container__wrap {
      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}

.container__wrap {
  padding-left: 0;
  margin-top: 60px;
  min-height: calc(100vh - 60px);
  transition: padding-left 0.3s;

  @media screen and (min-width: 576px) {
    padding-left: 170px;
  }
}
