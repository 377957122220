.rt-table {
  overflow-y: hidden;
  overflow-x: auto;
}

.rt-tbody {
  height: 100px;
  justify-content: flex-start;
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.rt-tr-group {
  min-height:25px
}

.rt-thead {
  flex: 0 0 auto;
}

.rt-th {
  font-weight: bold;
  color: black;
}

.rt-td {
  color: black;
  align-items: center;
}

.ReactTable .rt-td {
  padding:0px;
}

.ReactTable .rt-tr .action {
  transition: all .2s ease;
  text-align: center;
  color: red;
  transform: scale(0);
}

.ReactTable .rt-tr:hover .action {
  transform: scale(1.3);
}

.ReactTable .rt-tr:hover .rt-td {
  background-color: rgba(var(--color), $alpha: 0.1);
}

.rt-resizable-header-content {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

table {
  table-layout: fixed;
}

.venue-name {
  white-space: normal;
}