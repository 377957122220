.longText {
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
}

.longText:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}

fieldset {
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
}

legend {
  width: auto;
  font-size: small;
  padding: 0px 5px;
  margin: 0px;
  margin-left: 5px;
}
